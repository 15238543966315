import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from "axios";
import VueAxios from "vue-axios";
import Buefy from "buefy";
import VueMeta from 'vue-meta';

Vue.config.productionTip = false


Vue.prototype.$env = {
  siteTitle:"BONJOB | the power of recruiting",
  siteDescription:"The power of recruiting good people worldwide. Contact us: +1 514 913 6491",
  siteUrl:"https://bonjob.com/",
  shareImg:"/img/share.jpg",
  api: "https://bonjob.com/api",
  filesURL: "https://bonjob.com/userfiles"
}

// Vue.prototype.$env = {
//   siteTitle:"Bonjob.com",
//   siteUrl:"http://localhost/bonjob/",
//   shareImg:"/img/share.jpg",
//   api: "http://localhost/bonjob/api",
//   filesURL: "http://localhost/bonjob/files"
// }

Vue.use(Buefy, {
  defaultIconPack: "fas"
});
Vue.use(VueAxios, axios);
Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
