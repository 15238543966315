import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    ball:{
      overLogo:false,
      overForm:false
    }
  },
  mutations: {
    setOver(state, payload) {
      console.log("ball sate: ",payload);
      state.ball[payload.what] = payload.state;
    },
  },
  actions: {
  },
  modules: {
  }
})
