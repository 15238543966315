<template>
  <div id="app">
    <router-view/>
    <Footer/>
    <div class="ball" :class="{ 'ball-hide': ball.overForm, 'ball-eye': ball.overLogo }"></div>
  </div>
</template>
<script>

import Footer from '@/components/Footer.vue'
import { mapState } from "vuex";
export default{
  components: {
    Footer
  },
  computed: {
    ...mapState(["ball"]),
  },
  mounted() {
    console.log("APP MOUNTED!");
    console.log("Begin Token Check!");    
    if (!localStorage.bonToken) {
      this.getToken();
    }else{
      console.log("Token Present!");
      this.checkToken();
    }

    //===================================================

    const ball = document.querySelector('.ball');
console.log(ball);

let mouseX = 0;
let mouseY = 0;

let ballX = 0;
let ballY = 0;

let speed = 0.2;

// Update ball position
function animate() {
	//Determine distance between ball and mouse
	let distX = mouseX - ballX;
	let distY = mouseY - ballY;
	
	// Find position of ball and some distance * speed
	ballX = ballX + (distX * speed);
	ballY = ballY + (distY * speed);
	
	ball.style.left = ballX + "px";
	ball.style.top = ballY + "px";
	
	requestAnimationFrame(animate);
}
animate();

// Move ball with cursor
document.addEventListener("mousemove", function(event) {
	mouseX = event.pageX;
	mouseY = event.pageY;
});
  },
  methods:{    
    getToken() {  
        console.log("Getting Token..");
        //begin post --------------------------------------------
        this.axios
          .post(`${this.$env.api}/token.php`, {
            action: "getToken"
          })
          .then(response => {
            //console.log("RESPONCE:" + JSON.stringify(response));
            //console.log(response.data.message);
            if (response.data.success) {              
              localStorage.bonToken = response.data.token;
            }
          })
          .catch(error => {
            //this.isLoading = false;
            console.log(error);
          });
        //end post-------------------------------------------------
    },
    checkToken() {
      if (localStorage.bonToken) {
        console.log("Checking local Token..");
        //begin post --------------------------------------------
        this.axios
          .post(`${this.$env.api}/validate.php`, {
            token: localStorage.bonToken
          })
          .then(response => {
            // console.log("RESPONCE:" + JSON.stringify(response));
              console.log(response.data.message);
            if (!response.data.success) {
              localStorage.removeItem("bonToken");
              this.getToken();
            }
          })
          .catch(error => {
            //this.isLoading = false;
            console.log(error);
          });
        //end post-------------------------------------------------
      }else{
        console.log("Token Not found!");
        this.getToken(); 
      }
    }
  }
}
</script>



<style lang="scss">
  //Import Fonts
  @import url('https://fonts.googleapis.com/css2?family=Open+Sans+Condensed:wght@300;700&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Sen:wght@400;700&display=swap');
//  @import url('https://fonts.googleapis.com/css2?family=Roboto');
  //font-family: 'Open Sans Condensed', sans-serif;
  //font-family: 'Roboto', sans-serif;

  // Import Fontawesome
  @import 'static/fontawesome/css/all.min.css';
  // Import Bulma's core
  @import "~bulma/sass/utilities/_all";
  // Set your colors
  $primary: #000000; //#4ab600;
  // $modal-background-background-color: #000;
  $primary-invert: findColorInvert($primary);
  //theme mod
  $mydark: #23262b; //#00bb8f; //00D1B2
  $mylight: #ebebeb; //#00bb8f; //00D1B2
  // $dark: #0d4553;
  $filter: #F4F4F4; //#00bb8f; //00D1B2
  $sidebar-box-shadow: 0px;
  $sidebar-width: 330px;
  // $body-background-color: #4a4f54;
  $content-heading-color: #000000; 

  $sol-gray: rgb(83, 83, 83);
  $sol-gray2: #4b4b4b;
  $sol-gray3: #424242;
  $sol-gray4: #383838;
  $sol-gray5: rgb(44, 44, 44);

  $sol-light: #afafaf;
  $sol-light2: #dadada;
  $sol-light3: #f7f7f7;

  $sol-red: #ff0000;

  $label-weight: 100;
  $label-color: $primary;

  // $navbar-item-color: $sol-gray!important;
  // $navbar-dropdown-arrow: $sol-gray!important;

  $footer-background-color: $sol-gray;
  $footer-color: $sol-light2;
  $footer-padding: 2rem 1.5rem 2rem;

  $family-primary: 'Sen', sans-serif; //'Roboto', sans-serif;

  // Setup $colors to use as bulma classes (e.g. 'is-twitter')
  $colors: (
      "white": ($white, $black),
      "black": ($black, $white),
      "light": ($light, $light-invert),
      "dark": ($dark, $dark-invert),
      "primary": ($primary, $primary-invert),
      "info": ($info, $info-invert),
      "success": ($success, $success-invert),
      "warning": ($warning, $warning-invert),
      "danger": ($danger, $danger-invert),
      "mydark": ($mydark, $white),
      "mylight": ($mylight, $black),
      "filter": ($filter, $white),     
      "sol-gray": ($sol-gray, $white),
      "sol-gray2": ($sol-gray2, $white),
      "sol-gray3": ($sol-gray3, $white),
      "sol-gray4": ($sol-gray4, $white),
      "sol-gray5": ($sol-gray5, $white),
      "sol-light": ($sol-light, $white),
      "sol-light2": ($sol-light2, $sol-gray),
      "sol-red": ($sol-red, $white),
  );

  // Links
  $link: $primary;
  $link-invert: $primary-invert;
  $link-focus-border: $primary;

  $input-icon-color:$sol-light;
  $input-icon-active-color:$sol-gray;

  .sidebar-button{
    position: fixed;
    top: 25px;
    right:16px;
    z-index:40;
    cursor: pointer;
    display: none;
  }

  @media screen and (max-width: 1023px) {
     .sidebar-button{
       display:unset;
    }
  }


.header-title{
    color: #fff;
    font-size: 4rem;
    font-family: 'Open Sans Condensed', sans-serif;   
}
.header-subtitle{
    color: #fff;
    font-size: 2rem;   
}
//end nav icon


$card-header-background-color: $primary;

.card-header-image{
  background-color: #ffffff!important;
}

.card-header-stats{
  background-color: #ffffff!important;
}

// .card-header-actions{
//   background-color: #0f92ff!important;
// }

$card-header-color:$sol-light3;

$card-header-padding: 30px 20px;

.card-header-title{
  font-size: 1.6rem;
  text-transform: uppercase;

}

.card-footer{
  padding: 20px;
  flex-shrink: 0;
  justify-content: flex-end;
}

.card-footer-sample{
  padding: 0px!important;
  flex-shrink: unset;
  justify-content: unset;
}

.card-footer .button:not(:last-child) {
    margin-right: .5em;
}

// .content li + li {
//      margin-top: 0em!important; 
// }

// .content ul {
//     margin-left: 0em!important;
//     margin-top: 0em!important;
// }
  .main-bg{
    background: url("~@/assets/bg.jpg") #000000;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
  }
  .main-content{
    height: 100vh;  
    background-color: #000000;  
    // display: flex;
    // align-items: center;
    // justify-content: center;
    transition: background-color 0.8s ease;
  }

  .main-loaded{
    background-color: #ffffff3d !important;
  }

	.slogan{
		color: #ffffff;
		font-size: 3rem;
		font-weight: bold;
		line-height: 1;
    transition: color 1s ease;
	}

  .slogan-loaded{
		color: #000000!important;
	}

  .uploder{
		background-color: #ffffff;
		width: 300px;
    min-height: 400px;
		border-radius: 10px;
    padding: 20px 20px;
		/* margin: 20px 20px; */
    display: flex;
    flex-direction: column;
    justify-content: end;
	}

  .uploader-footer{
      height: 20vh;
      background-color: #000000;
      color: #ffffff;
  }


@media screen and (min-width: 1023px) {
  .main{
    padding-top: 40px;
    min-height: calc(100vh - 128px);
  }
}
@media screen and (max-width: 1025px) {
  .column {
    // padding: 0 !important;
  }
  .box {
    padding: 10px !important;
    margin-top:20px;
  }
  .columns {
    margin-left: 0 !important;
    margin-right: 0 !important;
    margin-top: 0 !important;
  }
  .main-content{
    height: 100vh;
  }
  .uploader-footer{
    height: 100%;
  }
}

.navbar.is-fixed-bottom, .navbar.is-fixed-top {
  z-index: 9999!important;
}


.loginbg{
  background: #1d1f22; 
}

.link-bt{
  cursor: pointer;  
}
.link-red{
  color:#ed3a25;
}
.link-green{
  color:#7CBC4B;
}

.icon-single{
  color: #3e8ed0;
}
.icon-group{
  color: #f14668;
}

.delete-bt, .edit-bt, .copy-bt, .send-bt{
  color:#c9c9c9;
  cursor: pointer;
  float: left;
}
.delete-bt:hover{
  color: #f14668;
}
.edit-bt:hover{
  color: #7CBC4B;
}

.copy-bt:hover{
  color: #3e8ed0;
}

.send-bt:hover{
  color: #3e8ed0;
}

.table {
  background-color: transparent!important;
}

.has-title{
  width: 100%;
  overflow: hisol-en;
}

.sol-title{
  font-family: 'Open Sans Condensed', sans-serif;
  font-size: 3rem;
  font-weight: bold;
}

.sol-separator{
  border: 1px solid #000000;
}

.control.has-icons-right .input, .control.has-icons-right .select select {
    padding-right: 0.6em !important;
}


.message textarea{
    border: 0;
    box-shadow: none!important;
    border-radius: 0!important;
    border-bottom: 1px solid #000000!important;
    resize: none;
    padding-left: 0!important;
}


input {
  border: 0!important;
  outline: 0;
  border-radius: 0!important;
  border-bottom: 1px solid #000000!important;
  box-shadow: none!important;
  padding-left: 0!important;
  padding-bottom: 0px!important;
}

select {
  border: 0!important;
  outline: 0;
  border-radius: 0!important;
  border-bottom: 1px solid #000000!important;
  box-shadow: none!important;
  padding-left: 0!important;
  padding-bottom: 0px!important;
}

.active-label .label {
  font-size: calc(1rem * 0.75)!important;
  margin-top:-5px;
}

.label {
  transition: all .2s cubic-bezier(.25,.8,.5,1);
  margin-top:15px;
  position: absolute;
  z-index: 5;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.label { 
  // font-weight: 100!important;
  // color: #000000;
  white-space: nowrap;
  pointer-events: none;
}

.ball {
	background: #fff;
	width: 80px;
	height: 80px;
	border-radius: 50%;
	position: absolute;
	top: 0;
	left: 0;
	transform: translate(-50%, -50%);
	mix-blend-mode: difference;
  pointer-events: none;
  transition: width .6s ease, height .6s ease, background .6s ease, opacity .6s ease;
}

.ball-hide {
  width: 0px!important;
	height: 0px!important;
}

.ball-eye {
  background: url("~@/assets/eye.png") #000000;
  background-repeat: no-repeat;
  background-size: cover;
  mix-blend-mode: normal!important;
  opacity:.8;
}

#app{
  position: relative;
  overflow: hidden;
  }
// input:after {
//     background-color: #000000;
//     border-color: #000000;
//     border-style: solid;
//     border-width: thin 0 thin 0;
//     transform: scaleX(0);
// }

  // Import Bulma and Buefy styles
  @import "~bulma";
  @import "~buefy/src/scss/buefy";
</style>
