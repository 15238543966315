<template>
  <div class="uploader-footer">
    <div class="container">
        <div class="columns pt-5">
          <div class="column">
            <div class="section-title mb-4">ADDRESS</div>

            <p>44 Benjamin Hudon<br/>
            Saint Laurent, QC H4N1H8<br/>
            Canada</p>
          </div>
          <div class="column">

            <div class="section-title mb-4">CONTACT</div>

            <p>Tel: +1 514 913 6491<br/>
            Email: hello@bonjob.com</p>
          </div>
          <div class="column">
            <div class="section-title mb-4">FOLLOW US</div>

            <a href="https://www.facebook.com/bonjob.hr/" target="_blank">Facebook</a>  |   <a href="https://www.linkedin.com/company/bonjob-hr" target="_blank">LinkedIn</a>  |   <a href="https://www.instagram.com/bonjob.hr.agency/" target="_blank">Instagram</a>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer',

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .section-title{
    font-size: 1.3rem;
    color: #ffffff;
  }
  h3 {
    margin: 40px 0 0;
  }
  ul {
    list-style-type: none;
    padding: 0;
  }
  li {
    display: inline-block;
    margin: 0 10px;
  }
  a {
    color: #ffffff;
  }

    a:hover {
    color: #ffffff;
    text-decoration: underline;
  }

  @media screen and (max-width: 1025px) {
    .uploader-footer{
      text-align: center;
    }
  }
</style>
