<template>
<div class="main-bg">
  <div class="main-content" :class="delayLoad ? 'main-loaded':''">

              <div class="logo-container" @mouseenter="isOverLogo" @mouseleave="isOutLogo">
            <img class="logo" src="~@/assets/bonjob_logo.png" alt=""/>
          </div>

          <div class="mt-4 eye"><img src="~@/assets/eye.png" width="80px" alt=""></div>


    <div class="container grow">
      <div class="columns">
        <div class="column logoflex">

      <div>
          <div class="slogan" :class="delayLoad ? 'slogan-loaded':''">
            the power of recruiting<br/>
            good people.<br/> 
            worldwide.
          </div>

          
          <div class="slogan2 mt-5" :class="delayLoad ? 'slogan-loaded':''">
            We deal with great people.<br/>
            You In?
          </div>
        </div>
        </div>
        <div class="column uploder-container">
          
          <div class="uploder" @mouseenter="isOverForm" @mouseleave="isOutForm">
            <div class="uploder-content">

              <div v-if="form.done" class="has-text-centered mb-5">
                <img src="~@/assets/smile.svg" width="100px" alt="Be Happy!" class="mb-5"><br/>
                We have received your information and we will contact you with updates.<br/>
                Thank you!
              </div>

              <div v-if="!form.done">

            <b-field v-if="!file" class="file is-primary mb5" :class="{'has-name': !!file}">
                <b-upload v-model="file" expanded>
                  <a class="button is-primary is-fullwidth">
                    <b-icon icon="plus-circle"></b-icon>
                    <span>Add CV</span>      
                  </a>           
                </b-upload>
            </b-field>          

              
              <b-tag 
                v-if="file"
                size="is-medium"
                attached
                closable
                aria-close-label="Close tag"
                @close="file = null">
                {{ shortName(file.name) }}
              </b-tag> 

              <div class="has-text-danger" v-if="!file && hasFileError">{{hasFileError}}</div>
          
          <div class="my-3">

          <b-field :type="form.type.status"
              :message="form.type.reply">
            <b-select placeholder="Select a category" expanded v-model="form.type.value">
                        <option
                            v-for="option in options"
                            :value="option"
                            :key="option">
                            {{ option }}
                        </option>
                    </b-select>
          </b-field>

            <b-field v-if="form.type.value=='Other'" label="Specify category" :class="labelOther || form.other.value ? 'active-label' : ''"
              :type="form.other.status"
              :message="form.other.reply"
            >
                <b-input
                  @focus="labelOther=true"
                  @blur="labelOther=false"
                  v-model="form.other.value"
                ></b-input>
            </b-field>
        
            <b-field label="Name" :class="labelName || form.name.value ? 'active-label' : ''"
              :type="form.name.status"
              :message="form.name.reply"
            >
                <b-input
                  @focus="labelName=true"
                  @blur="labelName=false"
                  v-model="form.name.value"
                ></b-input>
            </b-field>

            <b-field label="Email" :class="labelEmail || form.email.value ? 'active-label' : ''"
              :type="form.email.status"
              :message="form.email.reply"
            >
                <b-input 
                  type="email"
                  @focus="labelEmail=true"
                  @blur="labelEmail=false"
                  v-model="form.email.value"
                ></b-input>
            </b-field>

            <b-field label="Message" :class="labelMessage || form.message.value ? 'active-label' : ''">
              <b-input class="message" maxlength="200" type="textarea"
                @focus="labelMessage=true"
                @blur="labelMessage=false"
                v-model="form.message.value"
              ></b-input>
            </b-field>

            </div>

            <b-message
                  auto-close
                  :duration="10000"
                  :active.sync="form.result"
                  :type="form.status"
                >
                  {{ form.reply }}
            </b-message>

            <b-button :loading="isLoading" type="is-primary" @click="submitApplication()" expanded>Send</b-button>

            </div>

            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'Upload',
  // components: {
  //   HelloWorld
  // }
  data(){
    return{
      delayLoad: false,
      isLoading: false,
      file: null,
      fileName: null,
      hasFileError: null,
      labelName: false,
      labelOther: false,
      labelEmail: false,
      labelMessage: false,
      options:[
        "Customer Service",
        "Order Entry",
        "Buying",
        "Accounting",
        "Marketing",
        "IT",
        "Manufacturing",
        "Engineering ",
        "Sales Representative",
        "Building Maintenance",
        "Other"
      ],
      form:{
        type:{
          value: null,
          status: null,
          reply: null
        },
        other:{
          value: null,
          status: null,
          reply: null
        },
        name:{
          value: null,
          status: null,
          reply: null
        },
        email:{
          value:null,
          status: null,
          reply: null
        },
        message:{
          value:null,
          status: null,
          reply: null
        },
        status: null,
        reply: null,
        result: false,
        done: false
      }
    }
  },
  mounted(){
    setTimeout(()=>{this.delayLoad=true;},1500);
  },
  methods:{
    shortName(long) {
      return long.replace(/(.{20})..+/, "$1…");
    },

    submitApplication(){

      if (
            this.formCheck("name") &
            this.formCheck("type") &
            this.emailCheck() &
            this.fileCheck()
          ) {
            console.log("good!");
            //console.log(this.form);
            this.uploadFiles();
          }else{
             console.log("not good!");
             console.log("formCheck",this.formCheck("name") );
             console.log("formCheck",this.formCheck("type") );
             console.log("emailCheck",this.emailCheck());
             console.log("fileCheck",this.fileCheck());

          }

    },
    uploadFiles() {
      console.log("uploading...");
      this.isLoading = true;
      let formData = new FormData();
      formData.append("file", this.file);
      formData.append("token", localStorage.bonToken);
      formData.append("action", "single");
      //begin post --------------------------------------------
      this.axios({
        headers: {
          "Content-Type": "multipart/form-data"
        },
        method: "post",
        url: `${this.$env.api}/upload.php`,
        data: formData
      })
        .then(response => {
          this.isLoading = false;
          console.log(response.data);
          this.form.reply = response.data.message;
          if (response.data.success) {
            this.fileName = null;
            this.fileName = response.data.filename;
            //this.toast(response.data.message, "is-success");
            //
           // this.form.reply = response.data.message;
            this.form.result = true;
            this.form.status = "is-success";
            this.sendNotification();
          } else {
            this.form.result = true;
            //this.form.reply = response.data.message;
            this.form.status = "is-danger";
          }
        })
        .catch(error => {
          this.isLoading = false;
          console.log(error);
          this.form.result = true;
          this.form.reply = "Sorry, something went wrong, please try again.";
        });
        // .finally(() => {
        //   this.sendNotification();
        // });
    },
    sendNotification() {
          this.isLoading = true;
          console.log("Sending Notification...");
          //begin post --------------------------------------------
          this.axios
            .post(`${this.$env.api}/notify.php`, {
              token: localStorage.bonToken,
              type: this.form.type.value,
              other: this.form.other.value,
              name: this.form.name.value,
              email: this.form.email.value,
              message: this.form.message.value,
              file: this.fileName
            })
            .then(response => {
              this.isLoading = false;
              // console.log("RESPONCE:" + JSON.stringify(response));
                //console.log(response);
                //console.log(response.data.message);
                this.form.result = true;
                this.form.reply = response.data.message;
              if (response.data.success) {
                console.log(response.data);
                this.form.status = "is-success";
                this.form.done = true;
              }else{
                this.form.status = "is-danger";
              }
            })
            .catch(error => {
              this.isLoading = false;
              console.log(error);
              this.form.status = "is-danger";
              this.form.result = true;
              this.form.reply = "Sorry, something went wrong, please try again.";
            });
            // .finally(() => {
            //   this.form.done = true;
            // });
          //end post-------------------------------------------------       
      },
      fileCheck() {
        if (!this.file) {
          //this.toast("Please upload a Main Image for your listing", "is-danger");
          this.hasFileError = "Don't forget to upload a CV.";
          return false;
        } else {
          console.log("did check file");
          this.hasFileError = null;
          return true;
        }
      },
      formCheck(e) {
        if (!this.form[e].value) {
          this.form[e].reply = "This field is required";
          this.form[e].status = "is-danger";
          return false;
        } else {
          this.form[e].reply = null;
          this.form[e].status = "is-success";
          return true;
        }
      },
      emailCheck() {
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; //eslint-disable-line
        if (this.form.email.value === "" || !re.test(this.form.email.value)) { 
          this.form.email.status = "is-danger";
          this.form.email.reply = "Please enter a valid email address";
          return false;        
        } else {
          this.form.email.status = "is-success";
          this.form.email.reply = null;
          return true;  
        }
      },
      isOverForm(){
        console.log("isOverForm");
        this.$store.commit('setOver',{what:'overForm', state:true});
      },
      isOutForm(){
        console.log("isOutForm");
        this.$store.commit('setOver',{what:'overForm', state:false});
      },
      isOverLogo(){
        console.log("isOverLogo");
         this.$store.commit('setOver',{what:'overLogo', state:true});
      },
      isOutLogo(){
        console.log("isOutLogo");
        this.$store.commit('setOver',{what:'overLogo', state:false});
      }
    },//end methods
    metaInfo() {
      return {
        title: this.$env.siteTitle,
        htmlAttrs: {
          lang: "en"
        },
         meta: [
        {
          name: "description",
          content: this.$env.siteDescription
        },
        {
          property: "og:title",
          content: this.$env.siteTitle,
          vmid: "og:title"
        },
        {
          property: "og:description",
          content: this.$env.siteDescription,
          vmid: "og:description"
        },
        {
          property: "og:image",
          content: this.$env.shareImg,
          vmid: "og:image"
        },
        {
          property: "og:url",
          content: this.$env.siteUrl,
          vmid: "og:url"
        }
      ]
      };
    }, //end meta
}
</script>
<style scoped>
.main-content{
  position: relative;
}
.uploder-container{
  display: flex;
  align-items: center;
  justify-content: right;
  display: -webkit-flex;
  -webkit-justify-content: right;
  flex-direction: row-reverse;
}

.logo{
  width: 300px;
}

.logoflex{
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
}

.slogan2{
  font-size: 2em;
  line-height: 1;
  transition: color 1s ease;
}

.grow{
  height: 100vh;
}

.eye{
  position: absolute;
  top:40px;
  left:50px;
}

.logo-container{
  position: absolute;
  bottom: 40px;
  left:50px;
}


@media screen and (max-width: 1025px) {
  .logo{
    width: 200px;
  }
  .slogan{
    margin-top:30px;
    margin-bottom: 20px;
		font-size: 2rem;
   }
  .eye{
    display: none;
  }
  .slogan2{
    display: none;
  }
  .logoflex{
    flex-direction: column-reverse;
    align-items: center;
    height: unset;
  }
  .slogan{
    text-align: center;
  }
  .uploder{
    /* float: unset; */
    margin: auto;
  }
  .grow{
    height: unset!important;
  }
}

  .logo-container{
    width: fit-content;
  }
</style>
